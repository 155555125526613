.grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 1em;
}

.griditem {
	width: 100%;
	text-align: center;
}

.halfgrid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1em;
}

.halfgriditem {
	width: 100%;
	text-align: left;
}

@media (max-width: 992px) {
	.grid {
		grid-template-columns: auto;
	}

	.halfgrid {
		grid-template-columns: auto;
	}
}
