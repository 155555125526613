.features article .icon {
	-moz-flex-grow: 0;
	-webkit-flex-grow: 0;
	-ms-flex-grow: 0;
	flex-grow: 0;
	-moz-flex-shrink: 0;
	-webkit-flex-shrink: 0;
	-ms-flex-shrink: 0;
	flex-shrink: 0;
	display: block;
	height: 5em;
	line-height: 5em;
	margin: 0 2em 0 1em;
	text-align: center;
	width: 5em;
	border: solid 2px white;
	border-radius: 1em;
}

.features article .icon:before {
	color: #8666bd;
	font-size: 2.75rem;
	position: relative;
}
